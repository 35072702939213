import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeRacks = () => {
  return (
    <Layout>
      <Seo
        title="Bike Racks - BIKESAFE"
        description="Our vast range of bike racks can accommodate any number of bikes; from one to 24 in just one bike rack! Every product we stock is rust resistant, and will not only defy the weather, but the attempts of criminals too."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
              Bike Racks
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
            Our vast range of bike racks can accommodate any number of bikes; from one to 24 in just one bike rack! Every product we stock is rust resistant, and will not only defy the weather, but the attempts of criminals too.
            </p>
            <p className="flow-text">See our select range below to help solve your security needs:</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Two Tier Bike Rack</h3>
              <p>Space saving, superb usability and excellent value! The Two Tier Rack is a very innovative and user friendly cycle parking system which allows you to house twice as many bikes in the same area as any standard...</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe Two Tier Bike Rack</h3>
              <p>The BikeSafe Two Tier Bike Rack is a pioneering and user friendly cycle parking system, with excellent space saving bike storage, superb usability and high value; allowing you to house twice as many bikes...</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Free Standing Wall Dock</h3>
              <p>A variation on our popular Wall Dock, the Free Standing Wall Dock lets users make the most from floor space. The frame can be securely bolted to the floor in any position of the room.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">The Semi-Vertical Bike Rack</h3>
              <p>This bike rack can help contribute towards gaining ENE8 Cycle storage credits when used in the appropriate situation, under the Code for Sustainable Homes.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BikeRacks;